import React, { useState, useContext } from 'react'
import { LocationContext } from '../context/LocationContext'
import MapPicker from 'react-google-map-picker'
import mapStyles from '../style/mapStyles'

const defaultZoom = 12;
const defaultLocation = { lat: 55.7069812, lng: 12.5543848};

const LocationPick = (props) => {
    const {setNewLocation } = useContext(LocationContext)
    const {className} = props;
    const [zoom, setZoom] = useState(defaultZoom);

    function handleChangeLocation (lat, lng){
        setNewLocation(prevInfo => ({
            ...prevInfo,
            geo: {
                latitude: lat,
                longitude: lng
            }
        }))
    }
    
    function handleChangeZoom (newZoom){
        setZoom(newZoom);
    }

    return (
        <div className={className}>
            <MapPicker defaultLocation={defaultLocation}
                options={{ styles: mapStyles, disableDefaultUI: true }}
                zoom={zoom}
                style={{height:'700px'}}
                onChangeLocation={handleChangeLocation} 
                onChangeZoom={handleChangeZoom}
                apiKey={process.env.REACT_APP_MAPS_KEY}/>  
        </div>
    );
}

export default LocationPick;