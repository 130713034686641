const Profile = (props) => {

    const {photo = null, name = null } = props.user
    const {rank} = props

    return (
        <div className="profile flex flex-col items-center py-4">
            {photo && <img src={photo} alt="" />}
            <h1 className="text-center mt-4 text-4xl font-serif">{name}</h1>
            <p className="text-center font-mono">{ rank }</p>
        </div>
    )
}

export default Profile