import { useContext } from 'react';
import useSound from 'use-sound';
import enterSound from '../assets/enter.mp3'
import exitSound from '../assets/exit.mp3'
import menuSound from '../assets/menu1.mp3'
import { AuthContext } from '../context/AuthContext';

const SoundWrapper = ({className, children, type}) => {

    const {settings} = useContext(AuthContext)

    const [playEnter, enter] = useSound(
        enterSound,
        {volume: 1}
    )
    const [playExit, exit] = useSound(
        exitSound,
        {volume: 1}
    )
    const [playMenu, menu] = useSound(
        menuSound,
        {volume: 1}
    )
    let playSound = playEnter;

    let sound = enter;

    if(type === "menu"){
        playSound = playMenu;
        sound = menu;
    }else if(type === "exit"){
        playSound = playExit;
        sound = exit;
    }else{
        playSound = playEnter;
        sound = enter;
    }

    const handlePlay = () => {
        if(!sound.isPlaying && settings['Sound']){
            playSound()
        }
    }

    return (
        <div className={className} onClick={handlePlay}>{children}</div>
    )
}

export default SoundWrapper