import { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import SoundWrapper from '../components/SoundWrapper';
import SignIn from '../components/SignIn'
import { AppContext } from '../context/AppContext';

const GettingStarted = () => {
    const [step, setStep] = useState(0)
    const history = useHistory()

    const { images } = useContext(AppContext)

    useEffect(() => {

        if(step < 0) history.push("/") 
    })

    const Controls = () => {
        return (
            <div className="flex justify-center mt-4">
                <SoundWrapper>
                    <button className="font-mono text-gray mx-4" onClick={() => setStep(step - 1)}>Back</button>
                </SoundWrapper>
                {step < 3 && (
                    <SoundWrapper>
                        <button className="font-mono text-gray mx-4" onClick={() => setStep(step + 1)}>Next</button>
                    </SoundWrapper>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center min-h-full">
            <div className="container p-4">
                
                {step === 0 && (
                    <div>
                        <h1 className="text-6xl text-center mb-4">Your Stats</h1>
                        <img className="border border-gray rounded-xl mb-4 mx-auto w-56" src={images.stats} alt="" />
                        <p className="text-center">The Los Santos Fitness app helps you keep track of your stats</p>
                    </div>
                )}

                {step === 1 && (
                    <div>
                        <h1 className="text-6xl text-center mb-4">Training Spots</h1>
                        <img className="border border-gray rounded-xl mb-4 mx-auto w-56" src={images.spots} alt="" />
                        <p className="text-center">Track down training spots, and show the locals who's boss</p>
                    </div> 
                )}

                {step === 2 && (
                    <div>
                        <h1 className="text-6xl text-center mb-4">Scores</h1>
                        <img className="border border-gray rounded-xl mb-4 mx-auto w-56" src={images.scores} alt="" />
                        <p className="text-center">Compete against friends and foes and enter the high ranks</p>
                    </div> 
                )}

                {step === 3 && (
                    <div>
                        <h1 className="text-6xl text-center mb-4">Sign Up</h1>
                        <p className="text-center">Only google accounts are supported at the moment</p>
                        <SignIn />
                    </div>  
                )}
                
                <Controls />
            </div>
        </div>
    )
}

export default GettingStarted;