import { NavLink } from 'react-router-dom'
import SoundWrapper from '../components/SoundWrapper'

const Welcome = () => {
	return (
		<div className='flex flex-col justify-center items-center min-h-full'>
			<h1 className='text-6xl text-center mb-4'>Los Santos Fitness</h1>
			<SoundWrapper className="mb-2">
				<NavLink
					to='/login'
					className='text-xl font-mono text-gray'>
					Login
				</NavLink>
			</SoundWrapper>
			<SoundWrapper>
				<NavLink
					to='/getting-started'
					className='text-xl font-mono text-gray'>
					Get started
				</NavLink>
			</SoundWrapper>
		</div>
	)
}

export default Welcome
