import GoogleMapReact from 'google-map-react'
import { LocationContext } from '../context/LocationContext'

import React, { useContext, useEffect, useState } from 'react'
import mapStyles from '../style/mapStyles'
import Marker from '../components/Marker'
import Navbar from '../components/Navbar'
import axios from 'axios'
import Loading from '../components/Loading'
import SoundWrapper from '../components/SoundWrapper'
import { NavLink } from 'react-router-dom'

const GymMap = () => {
	const { locations, setLocations } = useContext(LocationContext)
	const [isLoading, setIsLoading] = useState(true)

	const displayMarkers = () => {
		return locations.map((location, index) => {
			return (
				<Marker
					id={index}
					key={index}
					location={location}
					lat={location.geo._latitude}
					lng={location.geo._longitude}
				/>
			)
		})
	}

	useEffect(() => {
		let mounted = true
		if (!locations) {
			axios
				.get(process.env.REACT_APP_API_URL + '/locations')
				.then((response) => {
					if (mounted) {
						setLocations(response.data)
						setIsLoading(false)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
		if (locations && isLoading && mounted) {
			setIsLoading(false)
		}
		return () => {
			mounted = false
		}
	})

	return (
		<div>
			{isLoading ? (
				<Loading text='Getting locations' />
			) : (
				<div
					className='map relative'
					style={{ height: '100vh', width: '100%' }}>
					<GoogleMapReact
						options={{ styles: mapStyles, disableDefaultUI: true, gestureHandling: 'greedy' }}
						bootstrapURLKeys={{
							key: process.env.REACT_APP_MAPS_KEY,
						}}
						defaultCenter={{ lat: 55.705122, lng: 12.572385 }}
						defaultZoom={12}>
						{locations && locations.length > 0 && displayMarkers()}
					</GoogleMapReact>
					<SoundWrapper>
						<NavLink
							className='fixed right-4 bottom-20 bg-darkGray w-10 h-10 p-2 font-bold text-xl text-gray inline-flex justify-center items-center rounded-full'
							to='/location/add'
							type='menu'>
							+
						</NavLink>
					</SoundWrapper>
				</div>
			)}
			<Navbar />
		</div>
	)
}

export default GymMap
