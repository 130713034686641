import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import StatViewer from '../components/StatViewer'
import ProfileHeader from '../components/ProfileHeader'
import SignOut from '../components/SignOut'
import { NavLink } from "react-router-dom";
import Navbar from "../components/Navbar";
import { StatsContext } from "../context/StatsContext";
import axios from 'axios';
import Loading from '../components/Loading'
import SoundWrapper from "../components/SoundWrapper";

const Profile = () => {
    const { user, setUser } = useContext(AuthContext);
    const { stats, setStats, rank, setRank } = useContext(StatsContext);
    const [ isLoading, setIsLoading ] = useState(true);

    const authToken = localStorage.getItem('AuthToken');
    const history = useHistory();

    useEffect(() => {
        let mounted = true

        if(!user){
            axios.defaults.headers.common = { Authorization: `${authToken}` };
            axios.get(process.env.REACT_APP_API_URL + '/user').then((response) => {
                if(mounted){
                    const user = response.data
                    setUser(user);
                    setStats(user.stats);
                    setRank(user.rank)
                }
            }).catch((error) => {
                if(error.response?.status === 403) {
                    history.push('/login')
                }
                console.log(error);
            });
        }

        if(user && isLoading && mounted){
            setIsLoading(false)
        }
        return () => {
            mounted = false
        }
    },[user, isLoading])

    return (
        <div className="flex justify-center pb-20">
            { isLoading ? <Loading text="Getting profile"/> :
            <div className="container p-4">
                <ProfileHeader user={user} rank={rank} />
                <StatViewer stats={stats} />
                <div className="flex flex-col items-center mt-8 font-mono text-gray">
                    <SoundWrapper className="mb-2">
                        <NavLink to="/leaderboard" className="font-mono">LEADERBOARD</NavLink>
                    </SoundWrapper>
                    <SoundWrapper className="mb-2">
                        <NavLink to="/updatestats" className="font-mono">UPDATE STATS</NavLink>
                    </SoundWrapper>
                    <SoundWrapper className="mb-2">
                        <NavLink to="/settings" className="font-mono">SETTINGS</NavLink>
                    </SoundWrapper>
                    <SignOut />
                </div>
            </div>
            }
            <Navbar />
        </div>
    )
}

export default Profile