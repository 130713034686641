import { useContext } from "react"
import { AppContext } from "../context/AppContext"

const Message = () => {
    const {message, setMessage} = useContext(AppContext)
    return (
        <div className={`fixed inset-0 bg-black flex flex-col justify-center items-center z-20 ${message ? '' : 'hidden'}`}>
            <p className="mb-2">{message}</p>
            <button className="font-mono text-gray" onClick={() => setMessage(null)}>Okay</button>
        </div>
    )
}

export default Message