import React, { createContext, useState } from "react";

export const StatsContext = createContext();

export const StatsProvider = ({ children }) => {
    const [stats, setStats] = useState(null);
    const [newStats, setNewStats] = useState(null);
    const [rank, setRank] = useState(null);
    const [form, setForm] = useState({
        respect: {
            "Muscle up": { value: false },
            "Straight bar dip": { value: false },
            "Handstand": { value: false },
            "Slav squat": { value: false },
            "Front lever": { value: false },
            "Back lever": { value: false },
            "L-sit": { value: false },
            "Single arm pull-up": { value: false },
        },
        muscle: {
            "Pull-ups": {
                value: 0,
            },
            Dips: { value: 0 },
            Squats: { value: 0 },
        },
        stamina: {
            Sprint: {
                value: 0,
                prefix: "m",
            },
            Jog: {
                value: 0,
                prefix: "km",
            },
        },
    });

    return (
        <StatsContext.Provider
            value={{
                stats,
                setStats,
                rank,
                setRank,
                form,
                setForm,
                newStats,
                setNewStats,
            }}
        >
            {children}
        </StatsContext.Provider>
    );
};
