import Attribute from './Attribute'

const StatViewer = (props) => {
    const { respect = 0, muscle = 0, stamina = 0, sexAppeal = 0 } = props.stats ? props.stats : 0;

    return (
        <div>
            <h2 className="text-6xl mt-8">Stats</h2>
            <Attribute name="Respect" percent={respect + "%"} />
            <Attribute name="Muscle" percent={muscle + "%"} />
            <Attribute name="Stamina" percent={stamina + "%"} />
            <Attribute name="Sex Appeal" percent={sexAppeal + "%"} />
        </div>
    )
}

export default StatViewer