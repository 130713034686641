import { useContext } from "react"
import { LocationContext } from "../context/LocationContext"
import SoundWrapper from "./SoundWrapper";
import Select from 'react-select'

const facilityOptions = [
    { value: 'Straight bars', label: 'Straight bars' },
    { value: 'Corner bars', label: 'Corner bars' },
    { value: 'Soft floors', label: 'Soft floors' },
    { value: 'Ropes', label: 'Ropes' },
    { value: 'Pull-up bars', label: 'Pull-up bars' }
  ]

const selectStyles = {
    control: (provided) => ({
        backgroundColor:'transparent',
        cursor:'pointer',
        display:'flex'
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft:0,
    }),
    singleValue: (provided) => ({
        ...provided,
        color:'inherit'
    }),
    placeholder: (provided) => ({
        ...provided,
        color:'inherit'
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor:'inherit',
        padding:15,
        paddingBottom: 0
    }),
    indicatorSeparator: (provided) => ({
        display:'none'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color:'inherit'
    }),
    option: (provided) => ({
        backgroundColor:'transparent',
        cursor:'pointer',
        paddingBottom:15
    }),
}

const FacilitateList = () => {

    const {newLocation, setNewLocation} = useContext(LocationContext);
    const changeFacility = (select) => {
        let newList = {...newLocation};
        newList.facilitates[select.name] = {name: select.value};
        setNewLocation(newList)
    }
    
     const addFacility = () => {

        setNewLocation(prevInfo => ({
            ...prevInfo,
            facilitates: [...prevInfo.facilitates, {name: ""}]
        }))
    }

    const removeFacility = (index) => {
        let newArray = {...newLocation}
        newArray.facilitates.splice(index, 1)
        setNewLocation(newArray)
    }
    return (
        <div className="flex flex-col items-start justify-start">
            <div className="flex flex-col items-start w-full">
                <label className="text-sm">Facilities</label>
                {newLocation.facilitates.map((facility, index) => {
                    return <div className="flex justify-between w-full mb-2" key={index}><Select className="w-full text-gray text-xl text-left bg-black focus outline-none mr-8 mb-1 placeholder-gray" styles={selectStyles} options={facilityOptions} isSearchable={false} inputId={index} name={index} onChange={(e) => {changeFacility({name: index, value: e.value})}} /><button className="text-gray font-mono" onClick={() => removeFacility(index)}>REMOVE</button></div>
                })}
            </div>
            <SoundWrapper>
                <button className="text-gray font-mono" onClick={addFacility}>ADD FACILITY</button>
            </SoundWrapper>
        </div>
        
    )
}

export default FacilitateList