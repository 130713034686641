const ProfilePreview = (props) => {

    const { user, index} = props

    return (
        <div className="flex items-center py-2">
            <p className="text-2xl">{index}</p>
            <img src={ user.photo } alt="" className="ml-2 mr-4 w-14 rounded-full"/>
            <div>
                <h5>{user.name}</h5>
                <p className="font-mono">{user.rank}</p>
            </div>
        </div>
    )
}

export default ProfilePreview