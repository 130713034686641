import React, { createContext, useEffect, useState } from 'react'

import onboardImage1 from '../assets/onboard1.jpg'
import onboardImage2 from '../assets/onboard2.jpg'
import onboardImage3 from '../assets/onboard3.jpg'

import checkmark from '../assets/checkmark.svg'
import dumbbell from '../assets/dumbbell.png'
import webFont from 'webfontloader'

export const AppContext = createContext()

/* const pushNotification = async () => {
	try{
		const messaging = firebase.messaging();
		const token = await messaging.getToken({vapidKey: process.env.REACT_APP_MESSAGING_KEY})
		if(token){
			console.log(token)
		}else{
			console.log("no token")
		}


	}
	catch (error) {
		console.error(error.message)
	}
} */

export const AppProvider = ({ children }) => {
	const images = {
		stats: onboardImage1,
		spots: onboardImage2,
		scores: onboardImage3,
		dumbbell: dumbbell,
		checkmark: checkmark,
	}

	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState(null)

	webFont.load({
		custom: {
			families: ['Beckett', 'Bank Gothic', 'Pricedown', 'Aharoni'],
		},
	})

	useEffect(() => {
		Object.entries(images).forEach(([key, src]) => {
			const img = new Image()
			img.src = src
		})
	}, [])

	return (
		<AppContext.Provider
			value={{ images, loading, setLoading, message, setMessage }}>
			{children}
		</AppContext.Provider>
	)
}
