import React, {useState, useRef, useEffect} from 'react';
import Dumbbell from '../assets/dumbbell.png'
import SoundWrapper from './SoundWrapper';
import { NavLink } from 'react-router-dom'


const Marker = (props) => {
    const [open, setOpen] = useState(false);
    const { name, handle, facilitates } = props.location
    const node = useRef()

    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        setOpen(true)
        return;
        
      }
      setOpen(false)
    }

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [])

    return (
      <div className="map__marker-wrapper" ref={node}>
        {open && <div className="map__marker-modal">
          <SoundWrapper type="exit">
            <button className="btn btn--close" onClick={() => {setOpen(false)}}>Close</button>
          </SoundWrapper>
          <h2>{ name }</h2>
          <h3>Facilities</h3>
          <SoundWrapper>
            <NavLink className="btn btn--enter" type="enter" to={`/location/${ handle }`}>Enter</NavLink>
          </SoundWrapper>
          <ul>
            {facilitates.map((facility, index) => {
              return <li key={facility.name + "-" + index}>{facility?.name}</li>
            })}
          </ul>
        </div>}
        <SoundWrapper type="menu">
          <div className="map__marker" title={name}>
            <img src={Dumbbell} alt="" />
          </div>
        </SoundWrapper>
      </div>

    );
  };

  export default Marker;