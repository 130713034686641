import axios from 'axios'
import { useContext, useState } from 'react'
import { LocationContext } from '../context/LocationContext'
import LocationPick from './LocationPick'
import FacilitateList from './FacilitateList'
import Navbar from './Navbar'
import SoundWrapper from './SoundWrapper'
import { NavLink } from 'react-router-dom'
import { AppContext } from '../context/AppContext'

const AddLocation = () => {
	const { newLocation, setNewLocation } = useContext(LocationContext)
	const { setLoading } = useContext(AppContext)
	const [image, setImage] = useState(null)
	const [messages, setMessages] = useState({
		image_upload: null,
		form_response: null,
	})

	const { setMessage } = useContext(AppContext)

	const handleChange = (event) => {
		const newValue = event.target.value
		const key = event.target.name
		setNewLocation((prevValues) => ({
			...prevValues,
			[key]: newValue,
		}))
	}

	const handleImageChange = (event) => {
		if (!event.target.files[0]) return

		const file = event.target.files[0]
		//const filesize = (file.size / 1024 / 1024).toFixed(4);
		const type = file.type
		if (type === 'image/png' || type === 'image/jpeg') {
			setImage(event.target.files[0])
			setMessages(() => {
				return { ...messages, image_upload: 'Image uploaded' }
			})
		} else {
			setMessages(() => {
				return {
					...messages,
					image_upload: 'File must be of type jpg or png',
				}
			})
		}
	}
	const formatHandle = (text) => {
		return text
			.toLowerCase()
			.replace(/[^a-z0-9]+/g, '-')
			.replace(/-$/, '')
			.replace(/^-/, '')
	}
	const submitLocation = async () => {
		if (!image) return setMessage('You must add an image of the location')
		if (!newLocation.name)
			return setMessage('You must add a title of the location')
		if (!newLocation.description)
			return setMessage('You must add a description of the location')
		if (newLocation.facilitates.length === 0)
			return setMessage('The location must have at least one facility')
		if (newLocation.geo.latitude === 0)
			return setMessage('You must place a marker on the map')

		try {
			setLoading('Adding location')
			await axios.post(
				process.env.REACT_APP_API_URL + '/location',
				newLocation
			)

			let form_data = new FormData()
			form_data.append('image', image)
			form_data.append('locationHandle', formatHandle(newLocation.name))

			await axios.post(
				process.env.REACT_APP_API_URL + '/location/image',
				form_data,
				{
					headers: {
						'content-type': 'multipart/form-data',
					},
				}
			)

			setMessages({
				...messages,
				form_response:
					'The location has been uploaded, you can find it on the map once we have reviewed it!',
			})
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.error(error)
			setMessages({
				...messages,
				form_response: error.message,
			})
		}
	}

	return (
		<div className='pb-20 flex flex-col flex-grow justify-center'>
			{!messages.form_response ? (
				<div>
					<div className='p-4'>
						<div className='flex flex-col'>
							<label className='text-left'>
								Image of Location
							</label>
							<div className='flex'>
								<label
									htmlFor='image'
									className='flex mr-2 flex-col font-mono text-gray cursor-pointer items-start mb-4'>
									ADD IMAGE
									<input
										name='image'
										className='hidden'
										id='image'
										type='file'
										onChange={handleImageChange}
									/>
								</label>
								<p>{messages.image_upload}</p>
							</div>
							<label className='flex text-sm flex-col items-start mb-4'>
								Name
								<input
									className='w-full bg-transparent text-xl text-gray border-gray placeholder-gray'
									placeholder='Bolsjefabrikken'
									name='name'
									type='text'
									value={newLocation.name}
									onChange={handleChange}
								/>
							</label>
							<label className='flex text-sm flex-col items-start mb-4 w-full'>
								Description
								<textarea
									maxLength='130'
									rows='3'
									placeholder='Top tier training spot with great bars'
									className='w-full resize-none bg-transparent border-gray text-gray text-xl placeholder-gray'
									name='description'
									type='text'
									value={newLocation.description}
									onChange={handleChange}
								/>
							</label>
							<label className='flex text-sm flex-col items-start mb-4 w-full'>
								Cons
								<textarea
									maxLength='130'
									rows='3'
									placeholder='Someoene often plays crossfit music'
									className='w-full resize-none bg-transparent border-gray text-gray text-xl placeholder-gray'
									name='cons'
									type='text'
									value={newLocation.cons}
									onChange={handleChange}
								/>
							</label>
						</div>
						<FacilitateList />
						<LocationPick className='rounded-lg overflow-hidden h-96 mb-8 mt-4' />
						<SoundWrapper className='mb-4'>
							<button
								onClick={submitLocation}
								className='font-mono text-gray'>
								ADD LOCATION
							</button>
						</SoundWrapper>
						<SoundWrapper className='mb-8' type="exit">
							<NavLink to="/map" className="font-mono text-gray">BACK TO MAP</NavLink>
						</SoundWrapper>
						<div>{messages.error}</div>
					</div>
					<Navbar />
				</div>
			) : (
				<div className='p-4'>
					<div className='text-2xl mb-4'>
						{messages.form_response}
					</div>
					<SoundWrapper>
						<NavLink className='font-mono text-gray' to='/map'>
							BACK TO MAP
						</NavLink>
					</SoundWrapper>
				</div>
			)}
		</div>
	)
}

export default AddLocation
