import { useState, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import FieldGroup from './FieldGroup';
import { StatsContext } from "../context/StatsContext";
import StatViewer from './StatViewer';
import axios from 'axios';
import useSound from 'use-sound';
import updateSound from '../assets/stats_update.mp3'
import SoundWrapper from './SoundWrapper';

const UpdateStats = () => {
    const authToken = localStorage.getItem('AuthToken');
    const { stats, setStats, form, newStats, setNewStats, setRank } = useContext(StatsContext); 
    const [step, setStep] = useState(0);
    const lastStep = 4;
    const [playUpdate] = useSound(
        updateSound,
        {volume: 1}
    )

    useEffect(() => {

        // Calc new stats
        let completed = {
            respect: 0,
            muscle: 0,
            stamina: 0,
        }
        const max = {
            respect: Object.keys(form.respect).length,
            muscle: 1000,
            stamina: 50,
        }

        // Count completion
        Object.values(form.respect).map(data => {
            const value = data.value
            if(value) completed.respect++;
            return value;
        })

        Object.values(form.muscle).map(data => {
            const value = data.value
            return completed.muscle += parseInt(value)
        })

        Object.values(form.stamina).map(data => {
            const value = data.value
            return completed.stamina += parseInt(value)
        })

        // Set percentage scores
        let newRespect = completed.respect > max.respect ? 100 : (completed.respect / max.respect) * 100
        let newMuscle = completed.muscle > max.muscle ? 100 : (completed.muscle / max.muscle) * 100 
        let newStamina = completed.stamina > max.stamina ? 100 : (completed.stamina / max.stamina) * 100
        const newValues = {
            respect: newRespect,
            muscle: newMuscle,
            stamina: newStamina,
            sexAppeal: (newRespect + newMuscle + newStamina) / 3
        }

        setNewStats(newValues)
    }, [form])

    useEffect(() => {
        if(stats){
            // Calculate new rank
            setRank(() => {
                if(stats.sexAppeal > 88) return 'Beast mode'
                if(stats.sexAppeal > 74) return 'Kingpin'
                if(stats.sexAppeal > 60) return 'CJ'
                if(stats.sexAppeal > 42) return 'Swole'
                if(stats.sexAppeal > 28) return 'Gym rat'
                if(stats.sexAppeal > 14) return 'Prospect'
                return 'Rookie'
            })

            // Post data
            axios.defaults.headers.common = { Authorization: `${authToken}` };
            axios.post(process.env.REACT_APP_API_URL + '/user', {
                data: {
                    stats: stats
                }
            }).then((response) => {
                console.log(response)
            }).catch((error) => {				
                console.error(error)
            });
            playUpdate()
        }
    }, [stats])

    const nextStep = () => {
        if (step < lastStep) {
            setStep(step + 1)
        }
    }

    const previousStep = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }

    const animateNewStats = () => {
        setTimeout(() => {
            setStats(newStats)
        }, 500)
    }

    if(step === lastStep) animateNewStats()

    return (
        <div className='flex flex-col updatestats wrapper min-h-full container p-4 pb-20'>
            {step !== lastStep && <h1 className="text-6xl mb-4">Update Stats</h1>}
            {step === 0 && 
                <div>
                    <p className="mb-4">Answer a series of questions to determine your stats. Only god can judge you.</p>
                </div>
            }

            {step === 1 && <FieldGroup data={form.respect} title="Which of the following can you do with confidence?" type="checkbox" />}

            {step === 2 && <FieldGroup data={form.muscle} title="On a single workout, how many can you do with ease?" type="number" prefix=""/>}

            {step === 3 && <FieldGroup data={form.stamina} title="How far can you run?" type="number" prefix="km" />}
            
            <div className="flex justify-between">
                {step > 0 && step < lastStep && <SoundWrapper><button onClick={previousStep} className="font-mono text-gray text-xl"> Previous </button></SoundWrapper>}
                {step !== lastStep && <SoundWrapper type="exit"><button onClick={nextStep} className="font-mono text-gray text-xl"> Next </button></SoundWrapper>}
            </div>            
                {step === lastStep && 
                <div className="text-left">
                    <h1 className="text-center text-yellow font-pass text-6xl">New stats</h1>
                    <p className="text-center mt-4 text-lg">We have updated your stats</p>
                    <StatViewer stats={stats} />
                    <SoundWrapper className="text-center mt-8" type="exit">
                        <NavLink to="/profile" className="text-gray font-mono">Back to profile</NavLink>
                    </SoundWrapper>
                </div>}

        </div>
    )

}


export default UpdateStats