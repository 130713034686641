import Field from './Field'
const FieldGroup = ({data, title, type, prefix}) => {
    
    return (
        <div className="flex-grow">
            <p>{title}</p>
            <div className="fieldgroup flex flex-col items-start text-gray my-4">
            {Object.keys(data).map(key => {
                return <Field label={key} data={data} type={type} />
            })}
            </div>
        </div>
    )
}

export default FieldGroup;