import React, { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
	const [loggedIn, setLoggedIn] = useState(false)
	const [user, setUser] = useState(null)
	const [settings, setSettings] = useState(
		window.localStorage.getItem('settings')
			? JSON.parse(window.localStorage.getItem('settings'))
			: {
					Sound: true,
			  }
	)
	const [consent, setConsent] = useState(
		window.localStorage.getItem('consent') ? true : false
	)
	const [users, setUsers] = useState(null)

	useEffect(() => {
		if(consent){
			window.localStorage.setItem('consent', true)
		}else{
			window.localStorage.removeItem('consent')
		}
	})

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				settings,
				setSettings,
				loggedIn,
				setLoggedIn,
				users,
				setUsers,
				consent,
				setConsent,
			}}>
			{children}
		</AuthContext.Provider>
	)
}
