import { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import SoundWrapper from "../components/SoundWrapper";
import ProfilePreview from "../components/ProfilePreview";
import axios from "axios";
import Loading from "../components/Loading";
import { AuthContext } from "../context/AuthContext";

const Leaderboard = () => {
    const { users, setUsers } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const authToken = localStorage.getItem("AuthToken");
    const history = useHistory();

    useEffect(() => {
        let mounted = true;
        if (!users) {
            axios.defaults.headers.common = { Authorization: `${authToken}` };
            axios
                .get(process.env.REACT_APP_API_URL + "/users")
                .then((response) => {
                    if (mounted) {
                        setUsers(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 403 && mounted) {
                        history.push("/login");
                    }
                    console.error(error);
                });
        }
        if (users && isLoading && mounted) {
            setIsLoading(false);
        }

        return () => {
            mounted = false;
        };
    });


    const sortedUsers = users?.sort((userA, userB) => {
        if (userA.stats.sexAppeal > userB.stats.sexAppeal) {
            return -1;
        } else if (userA.stats.sexAppeal < userB.stats.sexAppeal){
            return 1
        }else{
            return 0
        }
    });

    return (
        <div className="pb-20">
            {isLoading ? (
                <Loading text="Getting users" />
            ) : (
                <div className="p-4 min-h-full">
                    <h1 className="text-center text-6xl mb-4">Highscores</h1>
                    {sortedUsers.map((user, index) => {
                        return (
                            <ProfilePreview
                                user={user}
                                key={index}
                                index={index + 1}
                            />
                        );
                    })}
                    <SoundWrapper className="text-center mt-8" type="exit">
                        <NavLink to="/profile" className="text-gray font-mono">
                            Back to profile
                        </NavLink>
                    </SoundWrapper>
                </div>
            )}
            <Navbar />
        </div>
    );
};

export default Leaderboard;
