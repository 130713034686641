import './style/fonts.scss'
import './style/app.scss'
import './style/main.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Profile from './pages/Profile'
import GymMap from './pages/GymMap'
import AddLocation from './components/AddLocation'
import UpdateStats from './components/UpdateStats'
import Login from './pages/Login'
import Location from './pages/Location'
import { AuthProvider } from './context/AuthContext'
import { LocationProvider } from './context/LocationContext'
import { StatsProvider } from './context/StatsContext'
import { Helmet } from 'react-helmet'
import Leaderboard from './pages/Leaderboard'
import Settings from './pages/Settings'
import Welcome from './pages/Welcome'
import GettingStarted from './pages/GettingStarted'
import Cookies from './components/Cookies'
import { AppProvider } from './context/AppContext'
import Loading from './components/Loading'
import Message from './components/Message'

const registerServiceWorker = () => {
	try {
		if ('serviceWorker' in navigator) {
			window.addEventListener('load', async () => {
				const registration = await navigator.serviceWorker.register(
					'/sw.js'
				)
				console.log(
					'ServiceWorker registration successful with scope: ',
					registration.scope
				)
			})
		}
	} catch (error) {
		console.error(error.message)
	}
}

function App() {
	registerServiceWorker()

	return (
		<Router>
			<Helmet>
				<link rel='manifest' href='./manifest.json' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'></meta>
			</Helmet>
			<div className='App flex flex-col'>
				<AppProvider>
					<AuthProvider>
						<LocationProvider>
							<StatsProvider>
								<Message />
								<Loading className='loader-fonts' />
								<Cookies />
								<Switch>
									<Route exact path='/' component={Welcome} />
									<Route
										path='/profile'
										component={Profile}
									/>
									<Route
										path='/getting-started'
										component={GettingStarted}
									/>
									<Route path='/login' component={Login} />
									<Route
										path='/location/add'
										component={AddLocation}
									/>
									<Route path='/map' component={GymMap} />
									<Route
										path='/updatestats'
										component={UpdateStats}
									/>
									<Route
										path='/leaderboard'
										component={Leaderboard}
									/>
									<Route
										path='/settings'
										component={Settings}
									/>
									<Route
										path='/location/:locationName'
										component={Location}
									/>
									<Route default component={Profile} />
								</Switch>
							</StatsProvider>
						</LocationProvider>
					</AuthProvider>
				</AppProvider>
			</div>
		</Router>
	)
}

export default App
