import { NavLink } from 'react-router-dom'
import SoundWrapper from './SoundWrapper';

const Navbar = () => {
    return(
        <nav className="wrapper">
            <ul>
                <li className="py-4">
                    <SoundWrapper type="menu">
                        <NavLink to="/map">Map</NavLink>
                    </SoundWrapper>
                </li>
                <li className="py-4">
                    <SoundWrapper type="menu">
                        <NavLink to="/profile">Stats</NavLink>
                    </SoundWrapper>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;