import React, { createContext, useState } from "react";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const [locations, setLocations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [newLocation, setNewLocation] = useState({
      published: false,
      name: '',
      description: '',
      cons: '',
      facilitates: [],
      geo: {
        latitude: 0,
        longitude: 0
      }
    });

    return (
      <LocationContext.Provider value={{
        locations,
        setLocations,
        selectedLocation,
        setSelectedLocation,
        newLocation,
        setNewLocation
        }}>
          {children}
      </LocationContext.Provider>
    );
};