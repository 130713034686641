import React, { useContext, useEffect, useState } from "react";
import { LocationContext } from "../context/LocationContext";
import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import Loading from "../components/Loading";

import axios from "axios";
import SoundWrapper from "../components/SoundWrapper";

const Location = ({ match }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { selectedLocation, setSelectedLocation } = useContext(
        LocationContext
    );
    const locationHandle = match.params.locationName;
    const location = selectedLocation;

    useEffect(async () => {
        let mounted = true;

        // Fetch location if state does not match handle
        if (!(location?.handle === locationHandle)) {
            const locationData = await axios
                .get(
                    process.env.REACT_APP_API_URL +
                        `/location/${locationHandle}`
                ).catch((error) => {
                    if(mounted){
                        setIsLoading(false)
                        setSelectedLocation(null)
                    }
                    throw Error(error.message)
                })
            
            if(mounted){
                setSelectedLocation(locationData.data)
            }
        }

        if (location && isLoading && mounted) {
            setIsLoading(false);
        }
        return () => {
            mounted = false;
        };
    });

    return (
        <div className="location">
            {isLoading && <Loading text="Getting location" />}
            {!isLoading && location && (
                <div>
                   <SoundWrapper>
                        <NavLink to="/map" className="btn btn--back">
                            Back
                        </NavLink>
                    </SoundWrapper> 
                    <div className="location__image-wrapper">
                        <div className="location__image-overlay"></div>
                        <img src={location.featured_image} alt="" />
                    </div>
                    <div className="wrapper location__content container p-4">
                        <h1 className="text-6xl">{location.name}</h1>
                        <div
                            className="text-left"
                            dangerouslySetInnerHTML={{
                                __html: location.description,
                            }}
                        />
                        <div className="location__facilities">
                            <h3>Facilities</h3>
                            <ul>
                                {location.facilitates.map((facility, index) => {
                                    return (
                                        <li key={index}>{facility?.name}</li>
                                    );
                                })}
                            </ul>
                            <div className="location__cons">
                                <h3>Cons</h3>
                                <div
                                    className="text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: location.cons,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && !location && (
                <div className="flex flex-col">
                    Location does not exist ...{" "}
                    <SoundWrapper className="mt-4" type="menu">
                        <NavLink className="font-mono text-gray" to="/map">Back to map</NavLink>
                    </SoundWrapper>
                </div>
            )}
            <Navbar />
        </div>
    );
};

export default Location;
