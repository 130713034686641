import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import SoundWrapper from "./SoundWrapper"

const Cookies = () => {
    const {consent, setConsent} = useContext(AuthContext)

    if (!consent) {
        return (
            <div className="flex flex-col items-center justify-center absolute bottom-0 right-0 left-0 bg-darkGray p-4 z-10">
                <p className="text-center">We only use necessary cookies.</p>
                <p className="text-center">You can always remove your consent, from the settings page</p>
                <SoundWrapper>
                    <button onClick={() => setConsent(true)} className="font-mono text-gray mt-4">Okay</button>
                </SoundWrapper>
            </div>
        ) 
    }else return null
}

export default Cookies