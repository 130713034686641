import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";

export const firebaseApp = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "training-app-ea32f.firebaseapp.com",
    projectId: "training-app-ea32f",
    storageBucket: "training-app-ea32f.appspot.com",
    messagingSenderId: "292312893958",
    appId: "1:292312893958:web:66205ec55cecb0bb269187",
    measurementId: "G-ZMDCTYFFJF"
});

export const auth = firebaseApp.auth();

export default firebase;