import { useContext } from 'react'
import { StatsContext } from "../context/StatsContext";
import SoundWrapper from './SoundWrapper';

const Field = ({label, data, type}) => {
    const { form, setForm } = useContext(StatsContext);
    const key = label;

    const handleChange = (event) => {
        data[key].value = type === 'checkbox' ? event.target.checked : event.target.value;
        setForm({
            ...form,
            ...data
        })
    }

    const decrement = () => {
        console.log(data[key].value)
        data[key].value = parseInt(data[key].value - 1)
        setForm({
            ...form,
            ...data
        })
    }

    const increment = () => {
        console.log(data[key].value)
        data[key].value = parseInt(data[key].value + 1)
        setForm({
            ...form,
            ...data
        })
    }

    return (
        <SoundWrapper type="menu" className="text-left w-full">
            {type === 'checkbox' ?
            <label className="font-mono text-2xl" data-checked={data[key].value}>
                {label}
                <input type={type} className="hidden" defaultChecked={data[key].value} value={data[key].value} onChange={handleChange}/>
            </label>
            :
            <label className="font-mono w-full flex text-2xl">
                <p className="flex-grow text-white">{label}</p>
                <div className="flex">
                    <button className="outline-none" onClick={decrement}>-</button>
                    <input className="bg-transparent w-20 font-mono pl-2 flex-shrink text-gray text-center outline-none" type={type} defaultChecked={data[key].value} value={data[key].value} onChange={handleChange}/>
                    <button className="outline-none" onClick={increment}>+</button>
                    <p className="font-mono w-5 ml-2">{data[key].prefix}</p>
                </div>
            </label>
            }
        </SoundWrapper>
        
    )
}

export default Field;