import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import SoundWrapper from "../components/SoundWrapper";
import { NavLink } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";


const Settings = () => {
    const { user, settings, setSettings, setConsent} = useContext(AuthContext)
    const history = useHistory();

    if(!user) history.push('/login')

    const toggleSetting = (setting) => {
        setSettings({
            ...settings,
            [setting]: !settings[setting]
        })
    }
    
    const deleteProfile = async () => {
        await axios.post(process.env.REACT_APP_API_URL + `/user/delete/${user.uid}`).catch((error) => { throw Error(error.message)})

        history.push('/')
    }

    useEffect(() => {
        window.localStorage.setItem('settings', JSON.stringify(settings))
    })

    return (
        <div className="min-h-full flex justify-center">
            <div className="container p-4 flex flex-col">
                <h1 className="text-6xl mb-4 text-center">Settings</h1>
                <div>
                    {Object.keys(settings).map((setting, index) => {
                        return (
                            <div key={index} className="flex justify-between">
                                <label>{setting}</label>
                                    <SoundWrapper>
                                        <button className={settings[setting] ? 'font-mono' : 'font-mono text-gray'} onClick={() => toggleSetting(setting)}>{settings[setting] ? 'on' : 'off'}</button>
                                    </SoundWrapper>
                            </div>
                        )
                    })}
                    <SoundWrapper className="mt-8 font-mono text-gray">
                        <button onClick={() => setConsent(false)}>Remove cookie consent</button>
                    </SoundWrapper>
                    <SoundWrapper className="mt-2 font-mono text-gray">
                        <button onClick={deleteProfile}>Remove profile</button>
                    </SoundWrapper>
                </div>
                <SoundWrapper className="text-center mt-4" type="exit">
                    <NavLink to="/profile" className="text-gray font-mono">Back to profile</NavLink>
                </SoundWrapper>
            </div>
            <Navbar />
        </div>
    )
}

export default Settings