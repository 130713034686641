import SignIn from '../components/SignIn'
import {NavLink} from 'react-router-dom'

const Login = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-full">
            <h1 className="text-6xl text-center mb-4">Login</h1>
            <div className="mb-8">
                <SignIn />
            </div>
            <NavLink to="/getting-started" className="font-mono text-gray text-center">Create account</NavLink>
        </div>
    )
}

export default Login;