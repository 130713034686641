import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase, { auth } from "../firebase/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import axios from "axios";
import { AppContext } from "../context/AppContext";

export default function SignIn(props) {
  //get the user state from the context
  const history = useHistory();

  const {setLoading} = useContext(AppContext)

  const uiConfig = {
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: async (result) => {
        try {
          setLoading('Getting profile')
          const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            id_token: result.credential.idToken
          })

          console.log("id_token", loginResponse.data.token)
          localStorage.setItem("AuthToken", `Bearer ${loginResponse.data.token}`);
          history.push('/profile')
          setLoading(false)
        }
        catch (error) {
          console.error(error)
          history.push('/')
          setLoading(false)
        }
      },
    },
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };

  //if user exists or signed in, we redirect the page to home, else display the sign in methods with FirebaseAuth
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
}
