import { useContext } from 'react'
import Dumbbell from '../assets/dumbbell.png'
import { AppContext } from '../context/AppContext'

const Loading = (props) => {
	const {loading} = useContext(AppContext)
	return (
		<div
			className={`flex items-center justify-center fixed inset-0 bg-black z-20 ${props.className} ${loading ? 'app-loading' : ''}`}>
			<div className='animate-pulse flex flex-col items-center justify-center'>
				<img
					src={Dumbbell}
					alt=''
					className='mb-2 w-12 animate-bounce'
				/>
				<p>{props.text}{loading}</p>
			</div>
		</div>
	)
}

export default Loading
