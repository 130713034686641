import React, { useContext } from 'react'
import { auth } from '../firebase/firebase'
import { AuthContext } from '../context/AuthContext'
import SoundWrapper from './SoundWrapper'
import { useHistory } from 'react-router-dom'

export default function SignOut() {
	//get the user state from the context
	const { setUser, setLoggedIn } = useContext(AuthContext)
	const history = useHistory()
	const signOut = () => {
		auth.signOut()
		history.push('/')
		setUser(null)
		setLoggedIn(false)
		localStorage.removeItem('AuthToken')
	}
	return (
		<SoundWrapper type='exit'>
			<button onClick={signOut} className='font-mono'>
				SIGN OUT
			</button>
		</SoundWrapper>
	)
}
